var CryptoJS = require("crypto-js")

function encode(originalStr) {
    if (!originalStr) {
        return "";
    }
    originalStr = originalStr.trim();
    if (localStorage.getItem("secretKey")) {
        return CryptoJS.AES.encrypt(originalStr, localStorage.getItem("secretKey")).toString();
    } else {
        return originalStr;
    }
}

function decode(originalStr) {
    if (!originalStr) {
        return "";
    }
    originalStr = originalStr.trim();
    if (localStorage.getItem("secretKey")) {
        try {
            return CryptoJS.AES.decrypt(originalStr, localStorage.getItem("secretKey")).toString(CryptoJS.enc.Utf8) || originalStr;
        } catch (err) {
            return originalStr;
        }
    } else {
        return originalStr;
    }
}

export { encode, decode }